<template>
  <div class="bot">
    <div class="logo" 
      :style="{ 'background': 'url(' + require('../assets/nav/logo.png') + ') no-repeat center center', 'background-size': '100% 100%'}">
    </div>
    <div class="desc">
      Meta Browser is the First Scalable, Secure and Fast mobile browser based on Web3.0 Protocol.
      After 10 months of research and development, our team will create a Web3.0 entrance gate product for a massive
      global user base.    
    </div>
    <div class="b">
      <div class="b1 bb">
        <div class="title">Link exchange</div>
        <div class="item">bitcoin</div>
      </div>



    </div>
  </div>
</template>

<script >
export default {
  name: "Bottom", 

  methods : {
    gotodownload (){
      if(  this.$route.name == 'h5Download' ){
        window.scrollTo( 0 , 0)
      } else {
        this.$router.push({
          path: "/m/download" ,
        })
      }
    } ,
    gotoDetail (){
      if(  this.$route.name == 'h5Detail' ){
        window.scrollTo( 0 , 0)
      } else {
        this.$router.push({
          path: "/m/details" ,
        })
      }
    }



  }
}
</script>


<style lang="scss" scoped>
.bot {
  padding: 0 .31rem .59rem;
  .logo {
    width: 7.12rem;
    height: 1.83rem;
  }
  .desc {
    font-size: 0.41rem;
    color: #706F7F;
    line-height: 0.61rem;
    margin-bottom: .76rem;
  }
  .b {
    display: flex;
    flex-wrap: wrap;
    .b1 {
      width: 50%;
      .title {
        font-weight: 500;
        font-size: 0.46rem;
        color: #FFFFFF;
        line-height: 0.69rem;
        margin-bottom: .31rem;
      }
      .line {
        font-weight: 500;
        font-size: 0.36rem;
        color: #706F7F;
        line-height: 0.51rem;
        margin-bottom: .31rem;
      }
    }
    .bb {
      width: 100%;
      margin-top: .71rem;
      display: flex;
      flex-wrap: wrap;
      justify-content:   flex-start;
      .title {
        width: 100%;
      }
      .item {
        font-weight: 500;
        font-size: 0.36rem;
        color: #706F7F;
        line-height: 0.51rem;
        margin-right: .66rem;
        margin-bottom: .31rem;
      }
    }
  }
}
</style>