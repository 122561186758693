<template>
  <div>
    <Navbar :title="$t('message.homeTitle')" :iconshow='false' @changecont="changecont"></Navbar>
    <div class="_cont"  :style="{ 'background': 'url(' + require('../assets/download/bg1.png') + ') no-repeat center center', 'background-size': 'cover' , 'background-position' : 'center'   }">
      <div class="container">
        <div class="line1">Download the Meta Browser for Free</div>
        <div class="line2">Safely share your KYC, send crypto, and access financial services in Meta Browser marketplace with a single click.</div>
        <div class="line3">
          <!-- <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo1.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo2.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo3.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo5.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div> -->

          
          <div @click="gotogg" class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo4.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        </div>
      </div>
    </div>
    
    <div class="_cont1 container" id="Features">
      <div class="top" :style="{ 'backgroundImage': 'url(' + require('../assets/detail/detail1.png') + ') ' }">
      </div>

      <div class="title">
        Team introduction
      </div>
      <div class="desc">
        The team is distributed around the world, consisting of senior AI engineers, web3 engineers, cryptocurrency industry practitioners, 20 years of browser technology accumulation and development experience. The team is proficiented in browser kernel and have rich user expansion experience in browser market, and senior entrepreneurs with rich experience in Web3.0 and product operations.
      </div>
    </div>
    
    <!-- <b-navbar toggleable="lg"  variant="info" fixed="top" >BSTOOLS小工具集合 </b-navbar> -->
    <!-- <div class="cont2">
      <canvas id="canvas3d" width="3584" height="1750" style="display: block; width: 100%; height: 100%;"></canvas>
      <div class="dbtn" @click="gotodownload">Download app</div>

      <div class="desc">
        Trading on Meta Browser - Everyone's Favorite Cryptocurrency Exchange
      </div>
    </div> -->

    <!-- <div class="cont3"
      :style="{ 'background': 'url(' + require('../assets/home/bg3.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      <div class="line1">What is Meta Browser?</div>
      <div class="line2">
        Meta Browser is the First Scalable, Secure and Fast mobile browser based on Web3.0 Protocol.
        After 10 months of research and development, our team will create a Web3.0 entrance gate product for a massive
        global user base.
      </div>

      <div class="dbtn" @click="gotodetail"> Details</div>
    </div> -->

    <div class="cont4 container" style="margin-bottom: 70px;">
      <div class="title">Roadmap</div>
      <!-- <flickity ref="flickity" :options="flickityOptions">
        <div class="carousel-cell" v-for="item in cont4Data">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="date">
            {{ item.date }}
          </div>
          <div class="emphasis" v-html="item.content" :class="{ off: !item.status }"></div>
          <div style="height: 16px;" @click="item.status = !item.status">
            <div class="toggle open"
              :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
              v-show="!item.status">
            </div>
            <div class="toggle _close"
              :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
              v-show="item.status">
            </div>
          </div>

          <div class="_img"
            :style="{ 'background': 'url(' + require('../assets/home/' + item.img + '.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
          </div>

        </div>
      </flickity> -->
      <div style="display: flex;">
        <div class="carousel-cell" v-for="item in cont4Data">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="date">
          {{ item.date }}
        </div>
        <div class="emphasis" v-html="item.content" :class="{ off: !item.status }"></div>
        <div style="height: 16px;" @click="item.status = !item.status">
          <div class="toggle open"
            :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
            v-show="!item.status">
          </div>
          <div class="toggle _close"
            :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
            v-show="item.status">
          </div>
        </div>

        <div class="_img"
          :style="{ 'background': 'url(' + require('../assets/home/' + item.img + '.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
      </div>
      </div>


    </div>
    <div style="height: 68px;width: 100%;"  id="Community">

    </div>
    <div class="cont7 container">
      <div class="title">
        Community
      </div>
      <div class="logo_cont">
        <div class="logo logo1"
          :style="{ 'background': 'url(' + require('../assets/home/logo1.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
        <div class="logo logo2"
          :style="{ 'background': 'url(' + require('../assets/home/logo2.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
        <div class="logo logo3"
          :style="{ 'background': 'url(' + require('../assets/home/logo3.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
        <div class="logo logo4"
          :style="{ 'background': 'url(' + require('../assets/home/logo4.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
        <div class="logo logo5"
          :style="{ 'background': 'url(' + require('../assets/home/logo5.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>
      </div>

    </div>
    <div style="height: 68px;width: 100%;" id="Whitepaper">

    </div>
    <div class="cont8 container">
      <div class="title" >
        Whitepaper
      </div>
      <div class="line2">
        What is Meta Browser?
      </div>
      <div class="line2" style="margin-top:21px;">
        Meta Browser is the First Scalable, Secure and Fast mobile browser based on Web3.0 Protocol. After 10 months of research and development, our team will create a Web3.0 entrance gate product for a massive global user base. Our goal is to unify the WEB3 portal, pave a bridge for WEB2 users to enter the WEB3 world, expand the WEB3 users, and promote the maturity and growth of the WEB3 ecosystem.
      </div>

      <div class="line2" style="margin-top:21px;">
        Team: The team is distributed around the world, consisting of senior AI engineers, web3 engineers, cryptocurrency industry practitioners, 20 years of browser technology accumulation and development experience. The team is proficiented in browser kernel and have rich user expansion experience in browser market, and senior entrepreneurs with rich experience in Web3.0 and product operations.
      </div>
      <div class="line2" style="margin-top:11px;">Deck: <span @click="gotourl('https://docsend.com/v/wd5pc/metabrowser')" style="margin-left: .15rem;cursor: pointer;">https://docsend.com/v/wd5pc/metabrowser</span>        </div>
      <div class="line2" style="margin-top:11px;">Web： <span @click="gotourl('https://www.meta-browser.com')" style="cursor: pointer;">www.meta-browser.com</span>  </div>

      <div class="line3" @click.stop="gotoWhitepaper">
        The SelfKey DAO Whitepaper 02-03-2023
      </div>
    </div>
    <div style="height: 68px;width: 100%;"  id="Links">

    </div>
    <div class="cont9 container">
      <div class="title" >
        We have partnered with top companies
      </div>
      <div class="icon_cont">
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company1.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company2.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company3.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company4.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
      </div>
    </div>

    <div class="cont5"
      :style="{ 'background': 'url(' + require('../assets/home/bg4.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      <div style="height:1px"></div>
      <div class="line1">Are you ready to join us on our voyage?</div>
      <div class="line2">Then download the Meta Browser today!</div>
      <div class="line3" @click="gotogg">Download</div>
    </div>

    <div class="cont6 container">

    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Bottom from "@/components/Bottom";

import common from '../utils/common'
import { Application } from '@splinetool/runtime';
import Flickity from "vue-flickity";

// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    Navbar,
    Flickity,
    Bottom
  },
  data() {
    return {
      navdata: [],
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,

      },
      cont4Data: [{
        status: false,
        title: 'V20240830',
        date: '2024-08-30',
        content: `1. Central wallet: support transaction, transfer and transfer out, transaction records   <br> 
            2. Security: Support for fingerprint <br>
            3. DAPPS: classification, rating, and new product recommendation <br>
            4. Advertising interception, blacklist <br>
            5. Add-in: plug-in management <br>
            6. Points wall: integral list <br>
            7. White Paper website <br>` ,
        img: '0830',
      }, {
        status: false,
        title: 'V20240730',
        date: '2024-07-30',
        content: `1. Local wallet: coin supports BTC, EFT, SOL, token supports ERC 20, and supports transfer in and out  <br> 
            2. Central wallet: Support registration, login, and support the database recording of the number of tokens <br>
            3. Security: support private key encryption, payment password, lock screen password, backup, restore mnemonic words, and detect whether the mobile phone is being root <br>
            4. Home page: support market, app, custom website shortcut, support web3, web2 home page switch <br>
            5. Plugin: Support local plug-in loading <br>` ,
        img: '0730',
      }, {
        status: false,
        title: 'V20240930',
        date: '2024-09-30',
        content: `1. Hardware wallet: support Ledger, Trezor, imKey   <br> 
            2. Safety: support stress code and paste board safety <br>
            3. Welcome page, select web2 web3 <br>
            4. Decentralized domain names <br>
            5. Support for ipfs <br>` ,
        img: '0930',
      }
      ]

    }
  },
  methods: {
    gotodownload(link) {
      this.$router.push({
        path: "/download",
      })
    },
    gotoWhitepaper() {
      window.open("/WhitePaper021720.pdf", "_blank")
    },
    gotodetail(){
      this.$router.push({
        path: "/details",
      })
    },
    gotourl( url ){
      window.open( url , "_blank")
    },
    gotogg(){
      window.open("https://play.google.com/store/apps/details?id=com.bose.meta.browser&hl=en", "_blank")
    },
    changecont( item ){
      const element = document.getElementById(item );
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    // let Application = window.canvasApplication  ;
    // const canvas = document.getElementById('canvas3d');
    // const app = new Application(canvas);
    // app.load('https://prod.spline.design/eT6zP1r0tv9g-2dz/scene.splinecode');
    // const onLoad = () => { }

    //this.navdata = common.getNavData( this )

  }
};
</script>


<style lang="scss" scoped>
.home {
  // padding: 20px;
}

.cont {
  padding-top: 70px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.item {
  margin-bottom: 30px;
}



.cont2 {
  width: 100%;
  height: 852px;
  position: relative;

  .dbtn {
    position: absolute;
    width: 240px;
    height: 70px;
    line-height: 70px;
    background: linear-gradient(90deg, #00B3FF 0%, #7C00FF 50%, #D800FC 100%);
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    left: 50%;
    margin-left: -120px;
    bottom: 165px;
    cursor: pointer;
  }

  .desc {
    position: absolute;
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    color: #DCDAFF;
    bottom: 105px;
  }
}

.cont3 {
  min-height: 638px;
  width: 100%;
  position: relative;

  .line1 {
    height: 75px;
    font-weight: bold;
    font-size: 51px;
    color: #FFFFFF;
    line-height: 75px;
    margin-top: 64px;
    text-align: center;
  }

  .line2 {
    width: 880px;
    min-height: 58px;
    text-align: center;
    font-size: 20px;
    color: #DCDAFF;
    line-height: 29px;
    margin: 39px auto 0;
    text-align: center;
  }

  .dbtn {
    width: 200px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(90deg, #00B3FF 0%, #7C00FF 50%, #D800FC 100%);
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    margin: 207px auto 0;
  }
}

.cont4 {
  // padding: 73px;
  box-sizing: border-radius;
  height: 510px;
  margin-top: 138px;
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
    margin-bottom: 73px;
  }
  .carousel-cell {
    width: 30%;
    height: 354px;
    margin-right: 30px;
    border-radius: 10px;
    counter-increment: carousel-cell;
    border-radius: 10px 10px 10px 10px;
    background: radial-gradient(57% 56% at 81% 77%, rgba(91, 53, 163, 0.58) 0%, #1A1B2C 100%);
    padding: 16px;
    color: #DCDAFF;
    overflow-y: auto;

    .title {
      font-weight: 500;
      font-size: 20px;
      color: #DCDAFF;
      line-height: 29px;
      margin-bottom: 1px;
    }

    .date {
      height: 17px;
      font-size: 12px;
      color: #DCDAFF;
      line-height: 17px;
      margin-bottom: 8px;
    }

    .off {
      height: 96px;
      overflow: hidden;
    }
  }

  .line1 {
    height: 59px;
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    margin-top: 147px;
  }

  .line2 {
    font-weight: 500;
    font-size: 16px;
    color: #DCDAFF;
    line-height: 24px;
    margin-top: 18px;
  }

  .line3 {
    height: 38px;
    font-weight: 500;
    font-size: 26px;
    color: #685FF5;
    line-height: 38px;
    margin-top: 37px;
    cursor: pointer;
  }

  ._img {
    width: 196px;
    height: 196px;
    margin: 0 auto;
  }

  .toggle {
    width: 16px;
    height: 16px;
    margin: 0px auto;
    padding: 3px 0;
  }

  ._close {
    margin: 3px auto;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transition: transform .5s;
  }

}
.cont7 {
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
    margin-bottom: 73px;
  }
  .logo_cont {
    display: flex;
    justify-content: space-between;
    .logo {
      width: 160px;
      height: 64px;
    }
  }
}

.cont8 {
  margin-bottom: 70px;
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
    margin-bottom: 73px;
  }
  .line2 {
    font-size: 16px;
    color: #DCDAFF;
    line-height: 24px;
    margin-top: 21px;
  }

  .line3 {
    font-weight: 500;
    font-size: 26px;
    color: #685FF5;
    line-height: 38px;
    margin-top: 61px;
    cursor: pointer;
  }
}
.cont9 {
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
    margin-bottom: 73px;
  }
  .icon_cont {
    display: flex;
    margin-top: 73px;
    margin-bottom: 126px;
    width: 100%;
    justify-content: space-between;
    .iconitem {
      width: 260px;
      height: 64px;
      // margin: 0 26px;
    }
  }
}

.cont5 {
  height: 350px;

  .line1 {
    height: 54px;
    font-weight: 800;
    font-size: 36px;
    color: #FFFFFF;
    line-height: 54px;
    text-align: center;
    margin-top: 96px;
  }

  .line2 {
    height: 31px;
    font-weight: 500;
    font-size: 18px;
    color: #DCDAFF;
    line-height: 31px;
    text-align: center;
    margin-top: 6px;
    text-align: center
  }

  .line3 {
    width: 140px;
    height: 50px;
    border-radius: 2px;
    border: 2px solid #FFFFFF;
    text-align: center;
    line-height: 46px;
    margin: 37px auto 0;
    font-weight: bold;
    font-size: 21px;
    color: #FFFFFF;
  }
}

.cont6 {
  height: 90px;
  width: 100%;
  padding-top: 18px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .logo {
    width: 160px;
    height: 64px;
    ;
  }
}

// canvas
canvas {
  outline: none;
  display: block;
}

// canvas
::v-deep .cont4 .flickity-prev-next-button.previous {
  left: -70px;
}

::v-deep .cont4 .flickity-prev-next-button.next {
  right: -70px;
}



._cont {
  height: 852px;
  width: 100%;
  background-repeat: no-repeat;
  padding-top: 120px;
  box-sizing: border-box;
  .line1 {
    width: 660px;
    font-weight: bold;
    font-size: 80px;
    color: #FFFFFF;
    line-height: 119px;
  }  
  .line2 {
    width: 576px;
    font-size: 22px;
    color: #706F7F;
    line-height: 33px;
    margin-top: 41px;
  }
  .line3 {
    margin-top: 70px;
    display: flex;
    .icon {
      width: 168px;
      height: 48px;
      border-radius: 24px;
      margin-right: 18px;
      cursor: pointer;
    }
  }
}

._cont1 {
  background: #04080B;
  width: 100%;
  .top {
    width: 1005px;
    height: 918px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
  }
  .title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 59px;
    text-align: center;
    margin-top: -82px;
  }
  .desc {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 22px;
    color: #706F7F;
    line-height: 33px;
    text-align: left;
    margin-top: 37px;
  }
}


</style>