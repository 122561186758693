<template>
  <div class="bot container">
    <div class="left_cont">
      <div class="l">
        <div class="logo" 
        :style="{ 'background': 'url(' + require('../assets/nav/logo.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="desc">
          Meta Browser is the First Scalable, Secure and Fast mobile browser based on Web3.0 Protocol.
          After 10 months of research and development, our team will create a Web3.0 entrance gate product for a massive
          global user base.      
        </div>
      </div>

      <div class="m m1">
        <div class="t">Link exchange</div>
        <div class="b">Bitcoin</div>
      </div>


      <!-- <div class="m m1">
        <div class="t">WALLET</div>
        <div class="b">Download</div>
        <div class="b">Ldentity Wallet</div>
        <div class="b">Mobile Wallet</div>
        <div class="b">Marketplace</div>
        <div class="b">For Developers</div>

      </div>
      <div class="m" style="margin-left: 74px;">
        <div class="t">ABOUT</div>
        <div class="b">Self Sovereign Ldentity</div>
        <div class="b">About Selfkey</div>
        <div class="b">Whitepaper</div>
        <div class="b">Self Sovereign Ldentity</div>
        <div class="b">About Selfkey</div>
        <div class="b">Whitepaper</div>
      </div> -->
    </div>






  </div>
</template>

<script >
export default {
  name: "Bottom", 

  methods : {
    gotodownload (){
      if(  this.$route.name == 'Download' ){
        window.scrollTo( 0 , 0)
      } else {
        this.$router.push({
          path: "/download" ,
        })
      }
    } ,
    gotoDetail (){
      if(  this.$route.name == 'Detail' ){
        window.scrollTo( 0 , 0)
      } else {
        this.$router.push({
          path: "/details" ,
        })
      }
    }



  }
}
</script>

<style lang="scss" scoped>
.bot {
  display: flex;
  padding-bottom: 50px;
  .left_cont {
    flex: 1;
    display: flex;
  }
  .l {
    .logo {
      width: 350px;
      height: 90px;
      cursor: pointer;
    }
    .desc {
      font-size: 16px;
      color: #706F7F;
      line-height: 24px;
      width: 360px;
      cursor: pointer;
    }
  }
  .m {
    padding-top: 34px;
    
    .t {
      height: 29px;
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 29px;
      margin-bottom: 17px;
      cursor: pointer;
    }
    .b {
      font-weight: 500;
      font-size: 16px;
      color: #706F7F;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: left;
      cursor: pointer;
    }
  }
  .m1 {
    margin-left: 66px;
  }
}
</style>