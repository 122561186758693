<template>
  <div>
    <Navbar></Navbar>
    <div class="cont">
      <div class="line1">Download the Meta Browser for Free</div>
      <div class="line2">Safely share your KYC, send crypto, and access financial services in Meta Browser marketplace with a single click.</div>
      <div class="cont2" :style="{ 'background': 'url(' + require('../assets/download/phone.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
      <div class="cont3">
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo1.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo2.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo3.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div @click="gotogg" class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo4.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo5.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
      </div>
      
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script >
import Navbar from "@/components4h5/Navbar" ;
import Bottom from "@/components4h5/Bottom";
export default {
  name: "h5Download",
  mounted(){
    window.scrollTo( 0 , 0);
  },
  components: {
    Navbar  ,
    Bottom
  },
  methods: {
    gotogg(){
      window.open("https://play.google.com/store/apps/details?id=com.bose.meta.browser&hl=en", "_blank")
    }
  }
}

</script>


<style lang="scss" scoped>
.cont {
  padding: .94rem 0 0 ;
  box-sizing: border-box;
  .line1 {
    width: 100%;
    font-weight: bold;
    font-size: 1.07rem;;
    color: #FFFFFF;
    line-height: 1.58rem;;
    padding: 0 .31rem;
    margin-bottom: .13rem;
  }  
  .line2 {
    width: 7.71rem;
    font-size: 0.31rem;
    color: #706F7F;
    line-height: 0.46rem;
    padding: 0 .31rem ;
  }
  .cont2 {
    height: 11.96rem;
  }
  .cont3 {
    padding: 1.27rem .51rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .icon {
      width: 4.27rem;
      height: 1.22rem;
      margin-bottom: .61rem;
    }
  }
}

</style>