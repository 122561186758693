<template>
  <div>
    <Navbar  :iconshow='false'></Navbar>
    <div class="_cont"  :style="{ 'background': 'url(' + require('../assets/download/bg1.png') + ') no-repeat center center', 'background-size': 'cover' , 'background-position' : 'center'   }">
      <div class="container">
        <div class="line1">Download the Meta Browser for Free</div>
        <div class="line2">Safely share your KYC, send crypto, and access financial services in Meta Browser marketplace with a single click.</div>
        <div class="line3">
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo1.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo2.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo3.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div @click="gotogg" class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo4.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo5.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script >
import Navbar from "@/components/Navbar" ;
import Bottom from "@/components/Bottom" ;
export default {
  name: "Download",
  mounted(){
    window.scrollTo( 0 , 0);
  },
  components: {
    Navbar  ,
    Bottom
  },
  methods : {
    gotogg(){
      window.open("https://play.google.com/store/apps/details?id=com.bose.meta.browser&hl=en", "_blank")
    }
  }
}

</script>

<style lang="scss" scoped>
._cont {
  height: 852px;
  width: 100%;
  background-repeat: no-repeat;
  padding-top: 120px;
  box-sizing: border-box;
  .line1 {
    width: 660px;
    font-weight: bold;
    font-size: 80px;
    color: #FFFFFF;
    line-height: 119px;
  }  
  .line2 {
    width: 576px;
    font-size: 22px;
    color: #706F7F;
    line-height: 33px;
    margin-top: 41px;
  }
  .line3 {
    margin-top: 146px;
    display: flex;
    .icon {
      width: 168px;
      height: 48px;
      border-radius: 24px;
      margin-right: 18px;
      cursor: pointer;
    }
  }
}
</style>