<template>
<div class="_cont nav">
  <div class="logo" 
    :style="{ 'background': 'url(' + require('../assets/nav/logo.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
    @click="gotohome"
    >
  </div>

  <div class="right">
    <b-button class="app">App</b-button>

    <!-- <div class="menu" 
      :style="{ 'background': 'url(' + require('../assets/nav/h5/menu.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
      >
    </div> -->

    <div class="menu" 
      :style="{ 'background': 'url(' + require('../assets/nav/h5/menu.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
      @click="rightcont = true "
      >
    </div>

    <van-popup v-model="rightcont" position="left" class="rightcont">
      <div class="logo" 
      :style="{ 'background': 'url(' + require('../assets/nav/logo.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
      >
    </div>


      <div class="item" @click="gotocont('Features')">
        <div class="icon" 
          :style="{ 'background': 'url(' + require('../assets/home/navicon1.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
          >
        </div>
        Features
      </div>
      <div class="item" @click="gotocont('Community')">
        <div class="icon" 
          :style="{ 'background': 'url(' + require('../assets/home/navicon2.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
          >
        </div>
        Community
      </div>
      <div class="item" @click="gotocont('Whitepaper')">
        <div class="icon" 
          :style="{ 'background': 'url(' + require('../assets/home/navicon3.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
          >
        </div>
        Whitepaper
      </div>
      <div class="item" @click="gotocont('Links')">
        <div class="icon" 
          :style="{ 'background': 'url(' + require('../assets/home/navicon4.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
          >
        </div>
        Links
      </div>
    </van-popup>






  </div>
</div>
</template>

<script>

export default {
  methods : {
    gotodownload( link ){
      if(  this.$route.name == 'h5home' ){
        this.$router.push({
          path: "/m/download" ,
        })
      }
    },
    gotohome(){
      if(  this.$route.name != 'h5home' ){
        this.$router.push({
          path: "/m/home" ,
        })
      }
    },
    gotocont( val ){
      this.$emit( "changecont" ,  val );
      this.rightcont = false ;
    }
  } ,
  data(){
    return {
      rightcont : false ,
    }
  }
}

</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
}
 
._cont {
  height: 1.27rem;
  display: flex;
  .logo {
    width: 4.73rem;
    height: 1.2rem;
    margin-top: 0.05rem;
    margin-left: 0.15rem;
    cursor: pointer;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .app {
    width: 1.53rem;;
    height: 0.71rem;
    line-height: 0.71rem;;
    margin-top: 0.28rem;
    font-size: 0.33rem;
    padding: 0;
    margin-right: 0.25rem;
  }
  .menu {
    width: 0.71rem;
    height: 0.71rem;
    margin-top: .3rem;
    margin-right: .2rem;
  }
}

::v-deep  .right .app {
  background-color: #685FF5;
  color: #fff;
  border: none;
} 
::v-deep  .right .app:hover , ::v-deep  .right .app:focus   {
  background-color: #685FF5;
  color: #fff;
  border: none;
} 

::v-deep .rightcont {
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  width : 6.87rem; ;
  .logo {
    width: 4.73rem;
    height: 1.2rem ;
    margin-bottom: .69rem;
  }
  .item {
    font-size: .5rem;
    display: flex;
    align-items: center;
    padding-left: .61rem;
    font-weight: 500;
    font-size: 0.51rem;
    color: #FFFFFF;
    margin-bottom: .61rem;
    .icon {
      width: 0.56rem;
      height: 0.56rem;
      margin-right: .18rem;
    }
  }
}

</style>