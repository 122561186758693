//英文语言包en.js

export default {
  route: {
    dashboard: 'Dashboard',
    manage: 'manage',
    users: 'users',
    menus: 'menus',
    logs: 'logs',
  },
  getNavData : [
   {
    title : "Video Downloader" ,
    name : "视频下载" ,
    route : "/videodownload",
    desc: "the best Video Downloader"
  } , 
  {
    title : "random number" ,
    name : "随机数" ,
    route : "/random",
    desc: "Enter the random range and generation number to generate a non-repeating group"
  } , {
    title : "draw lots" ,
    name : "抽签" ,
    route : "/ballot",
    desc: "When you can’t make a decision, try using this tool to draw lots"
  },{
    title : "BMI calculation" ,
    name : "BMI计算" ,
    route : "/bmi",
    desc: "Calculate your BMI to see if you are a standard weight!"
  },{
    title : "actual age" ,
    name : "实际年龄" , 
    route : "/age" ,
    desc: "Suddenly forget how old you are? Quickly calculate your age."
  },{
    title : "date countdown" ,
    name : "日期倒數" , 
    route : "/countdown" ,
    desc: "Do a quick count of the days until that date!"
  },{
    title : "date calculation" ,
    name : "日期计算" , 
    route : "/date-calc" ,
    desc: "Given a date, it will immediately tell you which day is before or after any number of days!"
  },{
    title : "days of birth" ,
    name : "出生天数" , 
    route : "/age-baby" ,
    desc: "Enter the birthday, give a specific date, and calculate how old the baby is!"
  },{
    title : "2023calendar" ,
    name : "假期查看" , 
    route : "/2023calendar" ,
    desc: "Find out when the holidays are this year!"
  },{
    title : "Palette" , 
    name : "调色盘" , 
    route : "/color" ,
    desc: "Pick a color and tell you the color code!"
  }
  ],
  datelang : {
      // the locale of formatting and parsing function
    formatLocale: {
      // MMMM
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      // MMM
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      // dddd
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      // ddd
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      // dd
      weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // first day of week
      firstDayOfWeek: 0,
      // first week contains January 1st.
      firstWeekContainsDate: 1,

    },
  } ,
  random : {
    title : "random number" ,
    text1 : "random number generator" , 
    text2 : "The best online random number generator, input how many random numbers you want, and you can randomly draw non-repeating numbers. It is very useful for playing big adventures or drawing lots! Let's see who is the lucky winner"  ,
    text3 : "lottery quantity" ,
    text4 : "Selection range" , 
    text5 : "Click me to draw!" ,
    text6 : "result" , 
    text7 : "to" , 
    result1 : "Please enter the correct value" ,
    result2 : "The number of draws is too large, please modify and try again" , 
  } ,
  Ballot : {
    title : "draw lots" ,
    text1 : "online lottery" ,
    text2 : "Too many choices to make a decision? Come and try the online lottery! Simple and easy to use, free online lottery program, self-made lottery content, the lucky one is you." , 
    text3: "lottery quantity" ,
    text4: "Example: 4" ,
    text5: "Selection range" , 
    text6: "Enter the lottery content, enter one content per line" , 
    text7: "reset" , 
    text8: "draw lots" ,
    text9: "result" ,
  },
  bmi : {
    title : "BMI calculation" ,
    text1: "Online BMI Calculator – Standard Weight Calculation Program" ,
    text2: "Online BMI weight calculation program to see if you meet the healthy standard value. Just enter your height and weight to see if you are in the ideal index range" ,
    text3: 'height' ,
    text4: "weight" , 
    text5: "Calculating BMI" ,
    text6: "weight range" ,
    text7: "underweight" ,
    text8: "normal range" ,
    text9: "abnormal range" ,
    text10 : "overweight" ,
    text11 : "obesity" , 
    text12 : "Your BMI will be displayed here" ,
    text13 : "Please enter the correct value" , 
    text14 : "your BMI" , 
    text15 : "height" , 
    text16 : "weight" , 
    text17 : "underweight" , 
    text18 : "normal range" , 
    text19 : "overweight" , 
  } ,
  age : {
    title : "Actual age" ,
    text1 : "Useful actual age calculator to see how old you are now" ,
    text2 : "Please select your date of birth" ,
    text3 : 'your actual age is'  ,
    text4 : "years" , 
    text5 : "0 years old" ,
    text6 : "month" ,
    text7 : "0 months" ,  
    text8 : "day" , 
    text9 : "0 days" , 
    text10 : "Please confirm that the date entered is correct and try again" , 
    text11 : "Actual age calculation",
  },
  countdown : {
    title : "date countdown" ,
    text1 : "Online date countdown tool" , 
    text2 : "The best date countdown timer ever! How long until the big day?" , 
    text3 : "Select a date to count down" , 
    text4 : "how many days" , 
    text5 : "Year" , 
    text6 : "month" , 
    text7 : "day" , 
    text8 : "Hour" , 
    text9 : "minute" ,
    text10 : "Second" , 
    text11 : "0 day" ,  
  } , 
  dateCalc : {
    title : "date calculation" , 
    text1 : "Date computer, calculate which day is a few days later (before)?"  ,
    text2 : 'An online date calculation tool to help you calculate what day will be today, tomorrow, a certain day after (before) after 10 days, 100 days, 200 days or any number of days' ,
    text3 : "select date" ,
    text4 : "50 days later:" ,
    text5 : "100 days later:" ,
    text6 : "365 days later:"  ,
    text7 : "1000 days later:" , 
    text8 : "customize" ,
    text9 : "e.g. 10"  ,
    text10 : "day" ,
    text11 : "calculate"  ,
    text12 : "selected date" ,
    text13 : "day" , 
    text14 : "later" ,
    text15 : "before" ,
    text16 : "is" ,
    text17 : "/" ,
    text18 : "/" , 
    text19 : "/" ,
    weekend :  [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
  },
  AgeBaby : {
    title : "days of birth" , 
    text1 : "day of birth calculation" ,
    text2 : "Online baby birth date calculator, year Y/month M/day D conversion age" ,
    text3 : "Please select your baby's date of birth" ,
    text4 : "count to date" , 
    text5 : "baby's actual age" ,
    text6 : 'year ' , 
    text7 : "month " ,
    text8 : "day" , 
    text9 : "Please confirm the date content, the calculated date cannot be less than the date of birth" ,
  } , 
  Calendar2023 : {
    title : "Chinese holidays" , 
    text1 : "2023 Chinese holidays" , 
    text2 : "2023 Chinese holidays is " ,
    text3 : "national holiday" ,
    text4 : "consecutive holidays" ,
    text5 : "date"  ,
    text6 : "Remark" ,  
    text7 : "New Year" , 
    text8 : "3 days" , 
    text9 : "12/31~1/2" , 
    text10 : "Spring Festival" , 
    text11 : "7 days" , 
    text12 : "1/21~1/27" , 
    text13 : "On January 28 and January 29, go to work." ,
    text14 : "Ching Ming Festival"  ,
    text15 : "1 day"  ,
    text16 : "4/5" ,
    text17 : "May Day" , 
    text18 : "5 days" , 
    text19 : "4/29~5/3" ,
    text20 : "On April 23rd and May 06th, go to work."  , 
    text21 : "dragon boat festival" ,
    text22 : "3 days" ,
    text23 : "6/22~6/24" ,   
    text24 : "June 25, work" , 
    text25 : "Mid-Autumn Festival/National Day"  ,
    text26 : "8 days" ,
    text27 : "9/29~10/6" , 
    text28 : "On October 7th and October 8th, go to work." ,
  } ,
  color : {
    title : "Palette"  ,
    text1 : "Color code table & color palette" ,
    text2 : "Most, super complete! Various color code tables, a list of color codes, whether you want red, blue, yellow, green, brown, black, orange, pink, or green, there are online color palettes for you to adjust colors" ,
    text3 : "color code" , 
    text4 : "copy color code" , 
    text5 : "successfully copied."  ,
  } ,
  videodownload : {
    title : "Video Downloader"  ,
    text1 : "Download" ,
    text2 : "See Supported Websites" , 
    text3 : "How to use video downloader?" ,
    text4 : "Find Videos" , 
    text5 : "Copy the  video URL by clicking Share and choosing Copy Link" ,
    text6 : "Paste Video" , 
    text7 : "Paste the video URL in the box above and hit the Download button." ,
    text8 : "Download Video" , 
    text9 : "Wait a few seconds for server to process and download video to your device." ,
    text10 : "Downloading..." , 
    text11 : "Loading...",
    text12 : "Your browser does not support the video tag." ,
    text13 : "MP4 Downloader URLs:" , 
    text14 : "Guide: How to download video & mp3 ?" , 
    text15 : "1. Move your mouse to the bottom right corner of the video player and click." , 
    text16 : "Show download menu for Mp4 video:" , 
    text17 : '2. Click "Download" button to download video or mp3.' ,
    text18 : "Show download button for Mp4 videos:" ,
    text19 : "Download failer" ,
    text20 : "It may take a few minutes to download the video, please be patient and do not leave" , 
    text21 : "Your download link is ready! " ,
    text22 : "Click the below button to start downloading..." ,
    text23 : "Video Downloader is an incredible tool that allows you to easily and quickly download  videos or audio or thumbnail. " , 
    text24 : "With this versatile website, you can download any video content from our website with just a few clicks.    " , 
    text25 : "We also provide an intuitive and user-friendly interface, making it easy for even the most novice computer/mobile users to download videos.    " , 
    text26 : "With its advanced downloading technology, Video Downloader can download at lightning speeds, ensuring that you never have to wait too long to enjoy your favorite content." , 
    text27 : "FAQs regarding Video Downloader" , 
    text28 : "What is Video Downloader? " , 
    text29 : "Video Downloader is website that allows you to download video content directly to your device    " ,
    text30 : "What devices are compatible with Video Downloader?  ",
    text31 : "Our website is compatible with all kinds of devices.    ",
    text32 : "Is this website free to use?" ,
    text33 : "Yes, this website is completely free to use. No login required!    " ,
    text34 : "Content downloaded using this website is copyright free?" ,
    text35 : "It is highly recommended to take permission from content creator before using it for any commercial usage."
  } ,
}