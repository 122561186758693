<template>
  <div>
    <Navbar></Navbar>
    <div class="top" :style="{ 'backgroundImage': 'url(' + require('../assets/detail/detail2.png') + ') ' }">

    </div>
    <div class="desc">
      The team is distributed around the world, consisting of senior AI engineers, web3 engineers, cryptocurrency industry practitioners, 20 years of browser technology accumulation and development experience. The team is proficiented in browser kernel and have rich user expansion experience in browser market, and senior entrepreneurs with rich experience in Web3.0 and product operations.
    </div>
    <Bottom></Bottom>

  </div>
</template>

<script >
import Navbar from "@/components4h5/Navbar" ;
import Bottom from "@/components4h5/Bottom";
export default {
  name: "h5Detail",
  mounted(){
    window.scrollTo( 0 , 0);
  },
  components: {
    Navbar  ,
    Bottom
  },

}

</script>

<style lang="scss" scoped>
.top {
  height: 12.72rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  // background-size: 100% 100%;
}
.desc {
  width: 100%;
  min-height: 4.07rem;
  font-size: 0.36rem;
  color: #706F7F;
  line-height: 0.51rem;
  padding: 0.33rem;
}
</style>



