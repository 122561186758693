import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Download from '../views/Download.vue'
import Detail from '../views/Detail.vue'

import H5HomeView from "../views/H5HomeView.vue"
import h5Download from "../views/h5Download.vue"
import h5Detail from "../views/h5Detail.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView ,
    // component: () => import("../views/Videodownload")
  },
  {
    path: '/m/home' ,
    name: 'h5home',
    component : H5HomeView
  },
  {
    path: '/download',
    name: 'Download',
    component: Download ,
  },
  {
    path: '/m/download',
    name: 'h5Download',
    component: h5Download ,
  },
  {
    path: '/details',
    name: 'Detail',
    component: Detail ,
  },
  {
    path: '/m/details',
    name: 'h5Detail',
    component: h5Detail ,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if( from.query.lang ){
    to.query.lang = from.query.lang
  }
  let ismobile =  ('ontouchstart' in document.documentElement) ;
  if (to.path == '/') {
    if ( ismobile ) {
      router.push({ path: '/m/home', });
    }
  } else if( to.path == '/m/home' ){
    if( !ismobile ){
      router.push({ path: '/', });
    }
  } else if( to.path == '/download' ){
    if ( ismobile ) {
      router.push({ path: '/m/download', });
    }
  } else if( to.path == '/m/download' ){
    if( !ismobile ){
      router.push({ path: '/download', });
    }
  } else if( to.path == '/details' ){
    if ( ismobile ) {
      router.push({ path: '/m/details', });
    }
  } else if( to.path == '/m/details' ){
    if( !ismobile ){
      router.push({ path: '/details', });
    }
  } else {
    router.push({ path: '/', });
  }




  
  next();

})




export default router
