<template>
  <div class="cont">
    <Navbar @changecont="changecont"></Navbar>

    <!-- <div class="cont2">
      <canvas id="canvas3dh5" width="200" height="160"  class="canvas3dh5"></canvas>
      <div class="dbtn" @click="gotodownload" >Download app</div>
      <div class="desc">Trading on Meta Browser - Everyone's Favorite Cryptocurrency Exchange</div>
    </div> -->
    <div class="downloadcont">
      <div class="line1">Download the Meta Browser for Free</div>
      <div class="line2">Safely share your KYC, send crypto, and access financial services in Meta Browser marketplace with a single click.</div>
      <div class="cont2" :style="{ 'background': 'url(' + require('../assets/download/phone.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
      <div class="contbtn">
        <!-- <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo1.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo2.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo3.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
        <div class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo5.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div> -->

        <div @click="gotogg" class="icon" :style="{ 'background': 'url(' + require('../assets/download/enlogo4.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
      </div>
      
    </div>

    <div id="Features" style="height: 1px;width: 100%;">

    </div>
    <div class="detailtop" :style="{ 'backgroundImage': 'url(' + require('../assets/detail/detail2.png') + ') ' }">

    </div>
    <div class="detailtitle">
      Team introduction
    </div>
    <div class="detaildesc">
      The team is distributed around the world, consisting of senior AI engineers, web3 engineers, cryptocurrency industry practitioners, 20 years of browser technology accumulation and development experience. The team is proficiented in browser kernel and have rich user expansion experience in browser market, and senior entrepreneurs with rich experience in Web3.0 and product operations.
    </div>




    <div class="cont4 container">
      <div class="_title">
        Roadmap
      </div>
      <div class="carousel-cell" v-for="item in cont4Data">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="date">
          {{ item.date }}
        </div>
        <div class="emphasis" v-html="item.content" :class="{ off: !item.status }"></div>
        <div @click="item.status = !item.status">
          <div class="toggle open"
            :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
            v-show="!item.status">
          </div>
          <div class="toggle _close"
            :style="{ 'background': 'url(' + require('../assets/home/arrow.png') + ') no-repeat center center', 'background-size': '100% 100%' }"
            v-show="item.status">
          </div>
        </div>

        <div class="_img"
          :style="{ 'background': 'url(' + require('../assets/home/' + item.img + '.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
        </div>

      </div>
    </div>
    <div id="Community" style="height: 1.07rem;width: 100%;">

    </div>
    <div class="cont6_title">
      Community
    </div>
    <div class="cont6">
      <div class="logo logo1"
        :style="{ 'background': 'url(' + require('../assets/home/logo1.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      </div>
      <div class="logo logo2"
        :style="{ 'background': 'url(' + require('../assets/home/logo2.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      </div>
      <div class="logo logo3"
        :style="{ 'background': 'url(' + require('../assets/home/logo3.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      </div>
      <div class="logo logo4"
        :style="{ 'background': 'url(' + require('../assets/home/logo4.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      </div>
      <div class="logo logo5"
        :style="{ 'background': 'url(' + require('../assets/home/logo5.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      </div>
    </div>

    <div id="Whitepaper" style="height: 1px;width: 100%;">

    </div>
    <div class="cont4 container">
      <div class="line1">
        Whitepaper
      </div>
      <div class="line2">
        What is Meta Browser?
      </div>
      <div class="line2" style="margin-top:21px;">
        Meta Browser is the First Scalable, Secure and Fast mobile browser based on Web3.0 Protocol. After 10 months of research and development, our team will create a Web3.0 entrance gate product for a massive global user base. Our goal is to unify the WEB3 portal, pave a bridge for WEB2 users to enter the WEB3 world, expand the WEB3 users, and promote the maturity and growth of the WEB3 ecosystem.
      </div>

      <div class="line2" style="margin-top:21px;">
        Team: The team is distributed around the world, consisting of senior AI engineers, web3 engineers, cryptocurrency industry practitioners, 20 years of browser technology accumulation and development experience. The team is proficiented in browser kernel and have rich user expansion experience in browser market, and senior entrepreneurs with rich experience in Web3.0 and product operations.
      </div>
      <div class="line2" style="margin-top:11px;">Deck: <span @click="gotourl('https://docsend.com/v/wd5pc/metabrowser')" style="margin-left: .15rem;cursor: pointer;">https://docsend.com/v/wd5pc/metabrowser</span>        </div>
      <div class="line2" style="margin-top:11px;">Web： <span @click="gotourl('https://www.meta-browser.com')" style="cursor: pointer;">www.meta-browser.com</span>  </div>

      <div class="line3" @click.stop="gotoWhitepaper">
        The SelfKey DAO Whitepaper 02-03-2023
      </div>
    </div>
    <div id="Links" style="height: 1px;width: 100%;">

    </div>
    <div class="cont7">
      <div class="title">
        We have partnered with  top companies
      </div>
      <div class="icon_cont">
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company1.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company2.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company3.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
        <div class="iconitem" :style="{ 'background': 'url(' + require('../assets/home/company4.png') + ') no-repeat center center', 'background-size': '100% 100%' }"></div>
      </div>
    </div>

    <div class="cont5"
    :style="{ 'background': 'url(' + require('../assets/home/bg4.png') + ') no-repeat center center', 'background-size': '100% 100%' }">
      <div style="height:.01rem"></div>
      <div class="line1">Are you ready to join us on our voyage?</div>
      <div class="line2">Then download the Meta Browser today!</div>
      <div class="line3" @click="gotogg">Download</div>
    </div>



    <Bottom></Bottom>

  </div>
</template>
<script >
import Navbar from "@/components4h5/Navbar" ;
import Bottom from "@/components4h5/Bottom";
import { Application } from '@splinetool/runtime';


export default {
  name: "HomeView",
  components: {
    Navbar  ,
    Bottom
  },
  data(){
    return {
      cont4Data: [{
        status: false,
        title: 'V20240930',
        date: '2024-09-30',
        content: `1. Hardware wallet: support Ledger, Trezor, imKey   <br> 
            2. Safety: support stress code and paste board safety <br>
            3. Welcome page, select web2 web3 <br>
            4. Decentralized domain names <br>
            5. Support for ipfs <br>` ,
        img: '0930',
      },{
        status: false,
        title: 'V20240830',
        date: '2024-08-30',
        content: `1. Central wallet: support transaction, transfer and transfer out, transaction records   <br> 
            2. Security: Support for fingerprint <br>
            3. DAPPS: classification, rating, and new product recommendation <br>
            4. Advertising interception, blacklist <br>
            5. Add-in: plug-in management <br>
            6. Points wall: integral list <br>
            7. White Paper website <br>` ,
        img: '0830',
      }, {
        status: false,
        title: 'V20240730',
        date: '2024-07-30',
        content: `1. Local wallet: coin supports BTC, EFT, SOL, token supports ERC 20, and supports transfer in and out  <br> 
            2. Central wallet: Support registration, login, and support the database recording of the number of tokens <br>
            3. Security: support private key encryption, payment password, lock screen password, backup, restore mnemonic words, and detect whether the mobile phone is being root <br>
            4. Home page: support market, app, custom website shortcut, support web3, web2 home page switch <br>
            5. Plugin: Support local plug-in loading <br>` ,
        img: '0730',
      }
      ]
    }
  },
  mounted () {
    window.scrollTo( 0 , 0);
  
    // let Application = window.canvasApplication  ;
    // const canvas = document.getElementById('canvas3dh5');
    // const app = new Application(canvas);
    // app.load('https://prod.spline.design/eT6zP1r0tv9g-2dz/scene.splinecode');
  },
  methods : {
    gotodownload( link ){
      this.$router.push({
        path: "/m/download" ,
      })
    },
    gotoWhitepaper() {
      window.open("/WhitePaper021720.pdf", "_blank")
    },
    gotodetail(){
      this.$router.push({
        path: "/m/details",
      })
    },
    gotourl( url ){
      window.open( url , "_blank")
    },
    gotogg(){
      window.open("https://play.google.com/store/apps/details?id=com.bose.meta.browser&hl=en", "_blank")
    },
    changecont( item ){
      const element = document.getElementById(item );
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

}


</script>

<style lang="scss" scoped>
.test {
  width: 10rem;
}

.cont2 {
  height: 7.12rem;
  position : relative ;
  .dbtn {
    position : absolute ;
    width: 3.36rem;
    height: 0.99rem;
    line-height : 0.99rem;
    background: linear-gradient( 90deg, #00B3FF 0%, #7C00FF 50%, #D800FC 100%);
    border-radius: 0.14rem;
    font-weight: bold;
    font-size: 0.34rem;
    color: #FFFFFF;
    text-align: center;
    left : 50% ;
    margin-left : -1.68rem;
    bottom : 0.71rem;
    cursor: pointer;
  }
  .desc {
    position : absolute ;
    width :100% ;
    height: 0.43rem;
    line-height : .43rem ;
    text-align: center;
    font-size: 0.28rem;
    color: #DCDAFF;
    bottom  : 0 ;
  }
  
}

.cont3 {
  min-height : 7.91rem ;
  width : 100% ;
  position : relative ;
  
  .line1 {
    height: 0.84rem;;
    font-weight: bold;
    font-size: 0.56rem;
    color: #FFFFFF;
    line-height: 0.84rem;;
    margin-top : 1.65rem ;
    text-align: center;
  }
  .line2 {
    width: 8.42rem;;
    min-height : 2.16rem; ;
    text-align : center ;
    font-size: 0.28rem;
    color: #DCDAFF;
    line-height: 0.43rem;
    margin : 0.1rem auto 0 ; 
    text-align: center;
  }

  .dbtn {
    width: 2.24rem;;
    height: 0.56rem;;
    line-height : 0.56rem;;
    background: linear-gradient( 90deg, #00B3FF 0%, #7C00FF 50%, #D800FC 100%);
    border-radius: 0.07rem;;
    font-weight: bold;
    font-size: 0.27rem; 
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    margin : 1.11rem auto 0 ; 
  }
}

.cont4 {
  // padding: 73px;
  box-sizing: border-radius;
  ._title {
    margin-top: 1.02rem;
    margin-bottom: .51rem;
    font-weight: bold;
    font-size: 0.56rem;
    color: #FFFFFF;
    text-align: center;
  }
  .carousel-cell {
    width: 9.36rem ;
    min-height: 8.98rem;;
    margin: 0 auto 0.46rem;
    border-radius: 0.25rem;    
    counter-increment: carousel-cell;
    background: radial-gradient(57% 56% at 81% 77%, rgba(91, 53, 163, 0.58) 0%, #1A1B2C 100%);
    padding: .41rem;
    color: #DCDAFF;
    // overflow-y: auto;
    .title {
      height: 0.74rem;
      font-weight: 500;
      font-size: 0.51rem;
      color: #DCDAFF;
      line-height: 0.74rem;
      margin-bottom: 0.03rem;
    }
    .date  {
      margin-bottom: 0.2rem;
      font-size: 0.28rem;
      color: #DCDAFF;
      line-height: 0.43rem;
    }
    .off {
      height: 2.1rem;
      overflow: hidden;
    }
    .emphasis {
      font-size: 0.36rem;
      color: #DCDAFF;
      line-height: 0.51rem;
    }
  }
  .line1 {
    height: 0.84rem;
    font-weight: bold;
    font-size: 0.56rem;
    color: #FFFFFF;
    line-height: 0.84rem;
    margin-top: 1.07rem;
    text-align: center;
  }

  .line2 {
    font-size: 0.31rem;
    color: #DCDAFF;
    line-height: 0.46rem;;
    margin-top: 0.38rem;
  }

  .line3 {
    height: 0.61rem;    
    font-weight: 500;
    font-size: 0.41rem;
    color: #685FF5;
    line-height: 0.61rem;
    margin-top: .38rem;
    cursor: pointer;
  }
  ._img {
    width: 4.99rem;
    height: 4.99rem;
    margin: 0 auto;
  }
  .toggle {
    width: 0.41rem;
    height: 0.41rem;
    margin: 0px auto;
    padding: .03rem 0;
  }

  ._close {
    margin: .03rem auto;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transition: transform .5s;
  }
}

.cont5 {
  height: 3.46rem;
  .line1 {
    height: 0.69rem;
    font-weight: bold;
    font-size:  0.46rem;;
    color: #FFFFFF;
    line-height: 0.69rem;;
    text-align: center;
    margin-top: .66rem;
  }

  .line2 {
    height: 0.79rem;;
    font-weight: 400;
    font-size: 0.31rem;;
    color: #DCDAFF;
    line-height: 0.79rem;;
    text-align: center;
  }

  .line3 {
    width: 2.19rem;;
    height: 0.76rem;;
    border-radius: 0.05rem;;
    border: 0.03rem solid #FFFFFF;
    text-align: center;
    line-height: .7rem;
    margin: .15rem auto 0;
    font-weight: bold;
    font-size: 0.33rem;;
    color: #FFFFFF;
  }
}

.cont6_title {
  font-weight: bold;
  font-size: 0.56rem;
  color: #FFFFFF;
  line-height: 0.84rem;
  text-align: center;
}
.cont6 {
  width: 100%;
  padding: 0 .31rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .logo {
    width: 2.83rem;
    height: 1.15rem;
    margin-right: .4rem;
  }
  .logo:nth-child(3n){
    margin-right: 0;
  }
  // .logo
}


.downloadcont {
  padding: .94rem 0 0 ;
  box-sizing: border-box;
  .line1 {
    width: 100%;
    font-weight: bold;
    font-size: 1.07rem;;
    color: #FFFFFF;
    line-height: 1.58rem;;
    padding: 0 .31rem;
    margin-bottom: .13rem;
  }  
  .line2 {
    width: 7.71rem;
    font-size: 0.31rem;
    color: #706F7F;
    line-height: 0.46rem;
    padding: 0 .31rem ;
  }
  .cont2 {
    height: 11.96rem;
  }
  .contbtn {
    padding: 1.27rem .51rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .icon {
      width: 5.55rem;
      height: 1.58rem;
      margin-bottom: .61rem;
    }
  }
}


.detailtop {
  height: 11.45rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  // background-size: 100% 100%;
}
.detailtitle {
  font-weight: bold;
  font-size: 0.56rem;
  color: #FFFFFF;
  line-height: 0.84rem;
  text-align: center;
  margin-bottom: .25rem ;
}
.detaildesc {
  width: 100%;
  min-height: 4.07rem;
  font-size: 0.36rem;
  color: #706F7F;
  line-height: 0.51rem;
  padding: 0.33rem;
}

.cont7 {
  .title {
    font-weight: bold;
    font-size: 0.56rem;
    color: #FFFFFF;
    line-height: 0.84rem;
    text-align: center;
    margin-top: 1.02rem;
    margin-bottom: 1.02rem;
    padding: 0 1.5rem;
    box-shadow: border-box;
  }
  .icon_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 .33rem;
    margin-bottom: 1.02rem;
    .iconitem {
      width: 2.8rem;
      height: .69rem;
      margin-right: .4rem;
      margin-bottom: .25rem;
    }
    .iconitem:nth-child(3) {
      margin-right: 0;
    }
  }

}
</style>