<template>
  <div class="container nav">
    <div class="logo" 
    :style="{ 'background': 'url(' + require('../assets/nav/logo.png') + ') no-repeat center center', 'background-size': '100% 100%'}"
    >
    </div>

    <div class="item" @click="gotocont('Features')">
      Features
    </div>
    <div class="item" @click="gotocont('Community')">
      Community
    </div>
    <div class="item" @click="gotocont('Whitepaper')">
      Whitepaper
    </div>
    <div class="item" @click="gotocont('Links')">
      Links
    </div>



    <div  class="right">
      <!-- <b-dropdown class="language_drop">
        <template v-slot:button-content>
          <div class="language" 
            :style="{ 'background': 'url(' + require('../assets/nav/language.png') + ') no-repeat center center', 'background-size': '100% 100%'}">
          </div>
        </template>
        <b-dropdown-item href="#">en</b-dropdown-item>
        <b-dropdown-item href="#">简体中文</b-dropdown-item>
      </b-dropdown> -->






      <b-button class="app">App</b-button>

    </div>
  </div>
</template>

<script>
import common from '../utils/common'

export default {
  props: { 
    title: {
      type: String,
      default: ''
    },
    iconshow: {
      type: Boolean,
      default: true
    }
  },
  name: "Navbar",
  data () {
    return {
      navdata : [] ,
      
    }
  } , 
  mounted () {
    // this.navdata = common.getNavData()
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData   ;
  } ,
  methods : {
    gotodownload( link ){
      if(  this.$route.name != 'Download' ){
        this.$router.push({
          path: "/download" ,
        })
      } else {
        window.scrollTo(0, 0);
      }
    },
    gotohome(){
      if(  this.$route.name != 'home' ){
        this.$router.push({
          path: "/" ,
        })
      }
    },
    gotoabout(){
      if(  this.$route.name != 'Detail' ){
        this.$router.push({
          path: "/details" ,
        })
      } else {
        window.scrollTo(0, 0);
      }
    },
    gotocont( val ){
      this.$emit( "changecont" ,  val )
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  margin-left: 0;
  margin-right:  0;
  background-color: #fff;
  border-bottom: 1px solid #E0E0E0;
  min-height: calc(2em + 1vw);
  .title {
    font-size: calc(1em + 1vw);
  }
}


a {
  text-decoration: none;
}
 





.container {
  height: 66px;
  display: flex;
  align-items: center;
  .logo {
    width: 240px;
    height: 60px;
    margin-top: 3px;
    margin-right: 42px;
    cursor: pointer;
  }

  .item {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 27px;
    padding: 5px 0;
    margin-right: 22px;
    margin-top: 5px;
    cursor: pointer;
  }
  .language {
    width: 32px;
    height: 32px;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .app {
    width: 116px;
    height: 40px;
    margin-left: 20px;
  }
}

::v-deep .show > .btn-secondary.dropdown-toggle {
  background-color: #09080B ;

}
::v-deep *  {
  .language_drop {
      .btn-secondary {
        padding: 0;
      }
      .dropdown-toggle::after {
        display: none;
      }
  }
  .btn-secondary{
    background-color: #04080B ;
    border:none;
    color : #fff

  }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle ,
  .btn-secondary:focus, .btn-secondary.focus
  {
    background-color: #09080B ;
    box-shadow: none;
  }
  
  .dropdown-menu.show {
    background-color: #21212C ;
    color : #fff;
    border-radius: 10px;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    background-color: #04080B ;
    color : #fff
  }
  .dropdown-item {
    color: #fff;
  }
}
::v-deep  .right .app {
  background-color: #685FF5;
  color: #fff;
  border: none;
} 
::v-deep  .right .app:hover , ::v-deep  .right .app:focus   {
  background-color: #685FF5;
  color: #fff;
  border: none;
} 

// #Wallet >>> .btn-secondary{
//   background-color: #04080B !important; 

// }
</style>