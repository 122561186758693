//中文语言包zh.js

export default {
  route: {
    dashboard: '首页',
    manage: '后台管理',
    users: '用户管理',
    menus: '菜单管理',
    logs: '日志管理',
  },
  getNavData : [
  {
      title : "视频下载器" ,
      name : "视频下载" ,
      route : "/videodownload",
      desc: "最好用的视频下载器"
  } ,
  {
    title : "随机数" ,
    name : "随机数" ,
    route : "/random",
    desc: "输入随机范围和生成数量，生成不重复的一组"
  } , {
    title : "抽签" ,
    name : "抽签" ,
    route : "/ballot",
    desc: "下不了决定的时候，试试用这个工具来抽签吧"
  },{
    title : "BMI计算" ,
    name : "BMI计算" ,
    route : "/bmi",
    desc: "算下你的BMI，看你是否标准体重！"
  },{
    title : "实际年龄" ,
    name : "实际年龄" , 
    route : "/age" ,
    desc: "突然忘了自己几岁？快速计算你的年龄。"
  },{
    title : "日期倒數" ,
    name : "日期倒數" , 
    route : "/countdown" ,
    desc: "快速算一下离那个日子还有几天！"
  },{
    title : "日期计算" ,
    name : "日期计算" , 
    route : "/date-calc" ,
    desc: "给出一个日期，马上告诉你任意天数前后哪一天！"
  },{
    title : "出生天数" ,
    name : "出生天数" , 
    route : "/age-baby" ,
    desc: "输入生日，给一个具体日期，算一下宝宝多大了！"
  },{
    title : "假期查看" ,
    name : "假期查看" , 
    route : "/2023calendar" ,
    desc: "看看今年的假期都是什么时候！"
  },{
    title : "调色盘" ,
    name : "调色盘" , 
    route : "/color" ,
    desc: "选择一个颜色，告诉你颜色代码！"
  }
  ] ,
  random : {
    title : "随机数" ,
    text1 : "随机数产生器" , 
    text2 : "最好用的线上随机数产生器，输入要多少个随机数，就能随机抽出不重复号码，玩大冒险或者抽签都很好用！来看看谁是中奖幸运儿"  ,
    text3 : "抽选数量" ,
    text4 : "抽选范围" , 
    text5 : "点我抽!" ,
    text6 : "结果" , 
    text7 : "到" , 
    result1 : "请输入正确的数值哦" ,
    result2 : "抽选数量太大了，请修改后重试" , 
  } ,
  Ballot : {
    title : "抽签" ,
    text1 : "线上抽签" ,
    text2 : "太多抉择做不了决定吗?来试试线上抽签吧!简单好用的,免费线上抽签程式,自制签筒内容,幸运者就是你。" , 
    text3: "抽选数量" ,
    text4: "例如：4" ,
    text5: "抽选范围" , 
    text6: "输入抽选内容，一行输入一条内容" , 
    text7: "重置" , 
    text8: "抽签" ,
    text9: "结果" ,
  } ,
  datelang : {
      // the locale of formatting and parsing function
    formatLocale: {
      // MMMM
      months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      // MMM
      monthsShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      // dddd
      weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      // ddd
      weekdaysShort: ['日', '一', '二', '三', '四', '五', '六'],
      // dd
      weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
      // first day of week
      firstDayOfWeek: 0,
      // first week contains January 1st.
      firstWeekContainsDate: 1,
      yearFormat: 'YYYY',
      // the calendar title of month
      monthFormat: 'MMM',
      // the calendar title of month before year
      monthBeforeYear: false,

    },
  } ,
  bmi : {
    title : "BMI计算" ,
    text1: "线上BMI计算器 – 标准体重计算程序" ,
    text2: "线上BMI体重计算程式，看你是不是符合健康的标准值，只要输入身高体重，就可看是否在理想的指数区间" ,
    text3: '身高' ,
    text4: "体重" , 
    text5: "计算BMI" ,
    text6: "体重范围" ,
    text7: "体重过轻" ,
    text8: "正常范围" ,
    text9: "异常范围" ,
    text10 : "超重" ,
    text11 : "肥胖" , 
    text12 : "将在这显示您的BMI" ,
    text13 : "请输入正确的数值哦" , 
    text14 : "你的BMI" , 
    text15 : "身高" , 
    text16 : "体重" , 
    text17 : "体重过轻" , 
    text18 : "正常范围" , 
    text19 : "体重过重" , 
  } ,
  age : {
    title : "实际年龄" ,
    text1 : "好用的实际岁数计算机，来看看你现在几岁了" ,
    text2 : "请选择你的出生年月日" ,
    text3 : '你的实际年龄为'  ,
    text4 : "岁" , 
    text5 : "0岁" ,
    text6 : "月" ,
    text7 : "0月" ,  
    text8 : "天" , 
    text9 : "0天" , 
    text10 : "请确认输入日期内容是否正确并重试" , 
    text11 : "实际年龄计算" , 
  } ,
  countdown : {
    title : "日期倒数" ,
    text1 : "线上日期倒数工具" , 
    text2 : "最好用的日期倒数计时器！计算到重要的日子还有多久？" , 
    text3 : "选择要倒数的日期" , 
    text4 : "还有多少天" , 
    text5 : "年" , 
    text6 : "月" , 
    text7 : "天" , 
    text8 : "小时" , 
    text9 : "分钟" ,
    text10 : "秒" , 
    text11 : "0天" ,  
  } , 
  dateCalc : {
    title : "日期计算" , 
    text1 : "日期计算机，推算几天后(前)是哪天？"  ,
    text2 : '线上日期计算工具，帮你推算出10天、100天、200天或任何天数之后的今天、明天、某天后(前)是哪天' ,
    text3 : "选择日期" ,
    text4 : "50天后:" ,
    text5 : "100天后:" ,
    text6 : "365天后:"  ,
    text7 : "1000天后:" , 
    text8 : "自定义" ,
    text9 : "例如 10"  ,
    text10 : "天" ,
    text11 : "计算一下"  ,
    text12 : "所选日期" ,
    text13 : "天" , 
    text14 : "后" ,
    text15 : "前" ,
    text16 : "是" ,
    text17 : "/" ,
    text18 : "/" , 
    text19 : "/" ,
    weekend :  [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ]
  } ,
  AgeBaby : {
    title : "出生天数" , 
    text1 : "出生天数计算机" ,
    text2 : "线上宝宝出生天数计算机，年Y/月M/日D换算年龄" ,
    text3 : "请选择宝宝的出生年月日" ,
    text4 : "计算至日期" , 
    text5 : "宝宝的实际年龄" ,
    text6 : '岁 ' , 
    text7 : "月 " ,
    text8 : "天" , 
    text9 : "请确认日期内容，计算日期无法小于出生年月日" ,
 
  } , 

  Calendar2023 : {
    title : "节假日安排" , 
    text1 : "2023年法定节假日安排" , 
    text2 : "2023年假期安排为" ,
    text3 : "国定假日" ,
    text4 : "连休天数" ,
    text5 : "日期"  ,
    text6 : "备注" ,  
    text7 : "元旦" , 
    text8 : "3天" , 
    text9 : "12/31(六)~1/2(一)" , 
    text10 : "春节" , 
    text11 : "7天" , 
    text12 : "1/21(六)~1/27(五）" , 
    text13 : "1月28日(星期六)、1月29日(星期日)、上班。" ,
    text14 : "清明节"  ,
    text15 : "1天"  ,
    text16 : "4/5(三）" ,
    text17 : "劳动节" , 
    text18 : "5天" , 
    text19 : "4/29(六)~5/3(三)" ,
    text20 : "4月23日(星期日)、5月06日(星期六)、上班。"  , 
    text21 : "端午节" ,
    text22 : "3天" ,
    text23 : "6/22(四)~6/24(六)" ,   
    text24 : "6月25日(星期日)、上班。" , 
    text25 : "中秋/国庆"  ,
    text26 : "8天" ,
    text27 : "9/29(五)~10/6(五)" , 
    text28 : "10月7日(星期六)、10月8日(星期日)、上班。" ,
  } ,
  color : {
    title : "调色盘"  ,
    text1 : "色码表＆颜色调色盘" ,
    text2 : "最多、超齐全！各种色码表，颜色代码一览，无论想要红色、蓝色、黄色、绿色、咖啡色、黑色、橘色、粉色、绿色通通有，还有线上色盘任你调配色彩" ,
    text3 : "颜色代码" , 
    text4 : "复制颜色代码" , 
    text5 : "已成功复制."  ,
  } ,
  videodownload : {
    title : "视频下载器" ,
    text1 : "点击下载" , 
    text2 : "查看支持的站点" , 
    text3 : "怎么使用视频下载器?",
    text4 : "查找视频" , 
    text5 : "通过单击分享按钮并选择复制链接来复制视频URL" ,
    text6 : "粘贴视频" , 
    text7 : "将视频URL粘贴到上面的框中，然后点击下载按钮" ,
    text8 : "下载视频" , 
    text9 : "等待几秒钟，让服务器处理视频并将其下载到您的设备" ,
    text10 : "下载中...",
    text11 : "加载中...",
    text12 : "您的浏览器不支持视频组件" ,
    text13 : "MP4 下载链接:" , 
    text14 : "提示: 如何下载视频 ?" , 
    text15 : "1. 将鼠标移至视频播放器的右下角并单击。" , 
    text16 : "显示Mp4视频下载按钮" , 
    text17 : '2. 点击 "Download" 按钮下载视频.' ,
    text18 : "显示视频下载按钮" ,
    text19 : "下载失败" ,
    text20 : "可能需要几分钟的时间进行下载，请耐心等待" , 
    text21 : "您的下载链接已准备就绪" ,
    text22 : "点击下方按钮开始下载..." , 
    text23 : "Video Downloader 是一款令人难以置信的工具，可让您轻松快速地下载视频或音频或缩略图。    " , 
    text24 : "通过这个多功能网站，您只需点击几下即可从我们的网站下载任何 YouTube 内容。    " , 
    text25 : "我们还提供直观且用户友好的界面，即使是最新手的计算机/移动用户也可以轻松下载视频。    " , 
    text26 : "凭借其先进的下载技术，Video Downloader 可以闪电般的速度下载，确保您永远不必等待太久才能享受您喜爱的内容。" , 
    text27 : "关于视频下载器的常见问题解答    " , 
    text28 : "什么是Video下载器？ " , 
    text29 : "Video Downloader 是一个允许您将 Video 内容直接下载到您的设备的网站    " ,
    text30 : "哪些设备与Video下载器兼容？ ",
    text31 : "我们的网站与各种设备兼容。    ",
    text32 : "这个网站可以免费使用吗？" ,
    text33 : "是的，这个网站是完全免费的。无需登录！    " ,
    text34 : "使用本网站下载的内容是否没有版权？" ,
    text35 : "强烈建议在将其用于任何商业用途之前获得内容创建者的许可。"

  }
}